// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { get } from 'lodash';

// Components
import Layout from '../components/layout';
import ActionPanel from '../components/action-panel';

// Utils
import ComponentMap from '../utils/component-map';
import SeoUtils from '../utils/seo-utils';

import '../styles/pages/carriers.scss';

const CARRIER_QUERY = graphql`
  query carrierTemplateQuery {
    allDatoCmsState {
      edges {
        node {
          id
          stateTitle
          stateSlug
        }
      }
    }
    datoCmsCarrierPage {
      id
      carrierLandingPageTitle
    }
  }
`;

const CarrierState = ({ pageContext }) => (
  <StaticQuery
    query={CARRIER_QUERY}
    render={(data) => {
      const pageTitle = get(data, 'datoCmsCarrierPage.carrierLandingPageTitle');
      const siteTitle = `${pageContext.carrierData.stateTitle} - ${pageTitle}`;
      const queryData = get(pageContext, 'carrierData.stateTemplate');
      const statesData = get(data, 'allDatoCmsState');

      // Seo
      const seoTags = get(pageContext, 'carrierData.seoMetaTags.tags');
      const seoProps = {
        description: SeoUtils.extractProperty(seoTags, 'name', 'description'),
        twitterCardType: SeoUtils.extractProperty(
          seoTags,
          'name',
          'twitter:card'
        ),
        cardImage: SeoUtils.extractProperty(seoTags, 'property', 'og:image'),
      };

      // Render components based on module
      const RenderComponents = queryData
        ? queryData.map((eacModule) => {
            const label = get(eacModule, 'model.apiKey');
            const isBanner = label === 'hero';
            const ComponentProps = {
              data: eacModule,
              ...(isBanner && {
                states: statesData,
                pageData: pageContext,
                  showInputArea: true,
                showCarrierFilter: true
              }),
            };
              const eachComponent = ComponentMap(
              label,
              ComponentProps,
              eacModule.id
            );
            return eachComponent;
          })
        : [];

      return (
        <div>
          <Layout title={siteTitle} seoProps={seoProps}>
            {RenderComponents}
            <ActionPanel />
          </Layout>
        </div>
      );
    }}
  />
);

CarrierState.propTypes = {
  pageContext: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

CarrierState.defaultProps = {
  pageContext: PropTypes.objectOf(PropTypes.string),
};

export default CarrierState;
